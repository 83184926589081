<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-2xl font-bold mr-auto">{{ $breadcrumb[$breadcrumb.length-1].title }}</h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <CKEditor v-model="template.Content.Content" v-bind="editorOptions" />
      </div>
      <div class="flex flex-row-reverse mt-5">
        <button type="button" class="button w-full sm:w-16 bg-theme-1 text-white" @click="save">
          儲存
        </button>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<style scoped>
.swiper-container {
  padding-top: 5px;
  padding-bottom: 30px;
}
</style>

<script lang="ts">
/* eslint-disable */
import { defineComponent, ref } from "@cloudfun/core";
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import "@/global-components/ckeditor/styles.css";

export default defineComponent({
  components: {},
  setup() {
    const template = ref({
      Id: 0,
      Name: "",
      Content: { Content: "" }
    });

    const editorOptions = {
      editor: ClassicEditor,
      config: {
        extraPlugins: [UploadAdapterPlugin],
        toolbar: {
          shouldNotGroupWhenFull: true,
          items: [
            "heading",
            "|",
            "fontFamily",
            "fontSize",
            "fontColor",
            "fontBackgroundColor",
            "|",
            "bold",
            "italic",
            "underline",
            "alignment",
            "bulletedList",
            "numberedList",
            "outdent",
            "indent",
            "highlight",
            "insertTable",
            "|",
            "link",
            "blockQuote",
            "imageInsert",
            "mediaEmbed",
            // 'codeBlock',
            "htmlEmbed",
            "|",
            "|",
            "undo",
            "redo",
            "sourceEditing"
          ]
        },
        removePlugins: ["Markdown"],
        image: {
          toolbar: [
            "imageTextAlternative",
            "imageStyle:full",
            "imageStyle:side",
            "linkImage"
          ]
        },
        table: {
          contentToolbar: [
            "tableColumn",
            "tableRow",
            "mergeTableCells",
            "tableCellProperties",
            "tableProperties"
          ]
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph"
            },
            {
              model: "heading1",
              view: { name: "h1", classes: "font-bold text-xl" },
              title: "Heading 1",
              class: "ck-heading_heading1"
            },
            {
              model: "heading2",
              view: { name: "h2", classes: "font-bold text-base" },
              title: "Heading 2",
              class: "ck-heading_heading2"
            }
          ]
        }
      }
    }

    return {
      template,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/files`,
      editorOptions,
      baseUrl: process.env.VUE_APP_BACKEND_URL,
    };
  },
  watch: {
    '$route.params.name': {
        handler: function(name) {
          if (!name) return;
          this.$model.dispatch("template/find", name).then(
            template => this.template = template,
            failure => this.$send("error", { subject: "執行失敗", content: failure.message })
          );
        },
        deep: true,
        immediate: true
      }
  },
  methods: {
    save() {
      if (this.template.Id === 0) { // insert
        this.$model.dispatch("template/insert", this.template).then(
          template => {
            this.template = template;
            this.$send("info", { subject: "執行完成", content: "儲存成功" });
          }, 
          failure => this.$send("error", { subject: "執行失敗", content: failure.message })
        )
      } else { // update
        this.$model.dispatch("template/update", this.template).then(
          template => {
            this.template = template;
            this.$send("info", { subject: "執行完成", content: "儲存成功" });
          }, 
          failure => this.$send("error", { subject: "執行失敗", content: failure.message })
        )
      }
    }
  }
});
</script>
